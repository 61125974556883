// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-aviso-de-privacidad-js": () => import("./../../../src/pages/legal/aviso-de-privacidad.js" /* webpackChunkName: "component---src-pages-legal-aviso-de-privacidad-js" */),
  "component---src-pages-legal-sla-js": () => import("./../../../src/pages/legal/sla.js" /* webpackChunkName: "component---src-pages-legal-sla-js" */),
  "component---src-pages-legal-terminos-y-condiciones-js": () => import("./../../../src/pages/legal/terminos-y-condiciones.js" /* webpackChunkName: "component---src-pages-legal-terminos-y-condiciones-js" */)
}

